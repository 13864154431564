import React from "react";
import "./Hero.css";
import { Fade } from "react-awesome-reveal";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Hero() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.replace("#", ""); // Get the id from hash (e.g. element1)
      const element = document.getElementById(elementId); // Find the element by id
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the element
      }
    }
  }, [location]);

  return (
    <section className="hero" id="hero">
      <div className="container-lg">
        <div className="hero-content">
          <div className="hero-all">
            <Fade direction="right" triggerOnce={true}>
              <h2 className="hero-heading lg">Zoe Nattestad</h2>
            </Fade>

            <Fade direction="right" triggerOnce={true}>
              <p className="hero-text">
                I'm Zoe, a Digital Designer specializing in UX, dedicated to
                crafting elegant, user-centric, and intuitive digital
                experiences. I'm eager to bring my creative skills to new and
                exciting projects.
              </p>
            </Fade>
          </div>
          <a href="#about">
            <div className="hero-img">
              <Fade direction="left" triggerOnce={true}>
                <img src="images/newFront.JPG" alt="zoe" />
              </Fade>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Hero;
